<template>
  <!-- 官方文件 -->
  <div>
    <!-- 头部 -->
    <Footer></Footer>
    <!-- 内容区域 -->
    <div class="content">
      <div class="content_box">
        <span class="content_box_tilte"> 5G融合新技术垂直产业创新应用 官方通知发布</span>
        <div class="content_box_BOX">
          <img src="./File_img/TonZhi.png" class="ss" alt="">
          <span class="content_inform">官方通知</span>
          <div class="content_text">
            <p><el-link type="info" @click="openfile1('1')">第十九届中信科移动通信技术股份有限公司——“5G融合新技术赋能垂直产业创新应用”比赛方案</el-link></p>
            <p><el-link type="info" @click="openfile1('2')">第十八届中信科移动通信技术股份有限公司——“5G+创新应用仿真设计”比赛方案</el-link></p>

          </div>
          <!-- <div class="pagination">
                  <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="60">
                </el-pagination>
              </div> -->
        </div>
      </div>

      <div class="content_box" style="margin-left: .5rem;">
        <span class="content_box_tilte"> 5G融合新技术垂直产业创新应用 官方文件发布</span>
        <div class="content_box_BOXTwo">
          <img src="./File_img/WenJ.png" class="pp" alt="">
          <span class="content_inform">官方文件</span>
          <div class="content_text">
            <p v-for="(item, index) in getData" :key="index">
              <el-link type="info" @click="openfile(item.accessory)" style="font-size: 0.16rem">{{ item.title }}</el-link>
            </p>
          </div>
          <div class="pagination">
            <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
              :hide-on-single-page="true" :pager-count="7" :page-size="6" :total="total">
            </el-pagination>
          </div>
        </div>
      </div>
      <br>
      <br>
    </div>
    <!-- 底部 -->
    <Bottom></Bottom>
  </div>
</template>
  
<script>
// @ is an alias to /src
import Footer from '@/components/Footer/Footer.vue'
import Bottom from '@/components/Bottom/Bottom.vue'
import $ from "jquery";
import { getLogin } from "api/apis.js"

export default {
  name: 'Home',
  components: {
    Footer,
    Bottom,
  },
  data() {
    return {
      total: "",
      page: "1",
      Title: "",
      getData: "",
    }
  },
  mounted() {
    this.Acquire()
  },
  methods: {
    handleCurrentChange(val) {
      console.log(val)
      this.page = val
      this.Acquire()
    },
    Acquire() {
      let dataobj = new FormData();
      dataobj.append('label', "文件"); //文件
      dataobj.append('page', this.page); //页数
      dataobj.append('limit', '6'); //条数

      getLogin.getFile(dataobj).then(res => {
        console.log(res)
        console.log(JSON.parse(res.data))
        this.getData = JSON.parse(res.data)

        this.getData.map((item, index) => {
          console.log(item.accessory)
          console.log(JSON.parse(item.accessory))
        });
        // console.log(JSON.parse(Data[0].accessory))
        this.total = res.count
      })
    },
    openfile(val) {
      if (JSON.parse(val).length == 0) {
        this.$message.error('无文件！');
        return false
      } else {
        // console.log(JSON.parse(val)[0].url)
        window.open(JSON.parse(val)[0].url)
      }
      // window.open('./static/WenJ/大唐杯”全国大学生新一代信息通信技术大赛-大赛总章程.pdf')
    },
    openfile1(type){
      if(type == '1'){
        window.open('./static/challenge/中信科移动通信技术股份有限公司——“5G融合新技术赋能垂直产业创新应用”比赛方案.pdf')
      }else if(type == '2'){
        window.open('./static/challenge/中信科移动通信技术股份有限公司——“5G+创新应用仿真设计”比赛方案.pdf')
      }

    }

  },

}
</script>
  
<style scoped lang="scss">
/deep/ .el-table .cell.el-tooltip {
  text-align: left;
}

/deep/.el-link__inner {
  color: #000;
  font-size: .16rem;
}

/deep/.el-link__inner:hover {
  color: #FE850F;
}

@import './File.scss'
</style>